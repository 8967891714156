<template>
  <div class="result">
    <div class="index_inner">
      <div class="index_banner">
        <img class="img" src="../assets/image/banner.jpg" alt="">
      </div>
      <router-view/>
    </div>
      <div class="result_info">
        <div class="alert_img">
          <img src="../assets/image/icon_correct.png" alt="">
        </div>
        <span class="result_title">{{staff.msg}}</span>
          <!--
        <div class="result_img">
                <img class="result_img_body" src="../assets/image/finish.png" alt="finish">
        </div>
        -->
      </div>
<!--
      <div class="info_warn">
        <p>如有问题请联系：<a href="mailto:shengming_service@126.com">shengming_service@126.com</a></p>
      </div>
      -->
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "score",
  data() {
    return {
      score: ''
    }
  },
  mounted() {
    this.score = this.getScore()
    // console.log(this.getScore());
    // console.log(this.getResult());
  },
  computed: {
    ...mapState({
      staff: state => state.staff
    })
  },
  methods: {
    ...mapGetters({
      getScore: 'getScore',
      getResult: 'getResult'
    }),
    staffInfo() {
      this.$router.push({path: 'staff'})
    }
  },

}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'

.result
  display flex
  flex-direction column
  align-items center
  //justify-content center
  height calc(100vh - 50vw)
  position relative

.result_info
  //background-color white
  padding 0 38px 50px
  //height 400px
  text-align center
  font-size 28px
  .alert_img
    width 150px
    height 150px
    border-radius 50%
    background-color green
    margin 5vh auto 0 auto
    img
      width 100%
      height 100%
  p
    margin 0;
  .index_body_img
    width 100%
    display flex
    flex-direction column
    align-items center
    img
      width 360px
      padding 10px 0
    span
      padding 10px 0

.result_title
  text-align center
  display block
  font-size 72px
  font-family Source Han Sans CN;
  font-weight bold
  color $theme
  line-height 69px
  padding 40px 0


.result_img
  display flex
  align-items center
  justify-content center
  margin-top  24px
  margin-bottom  46px
  .result_img_body
    width 400px


.index_inner {
  width: 750px;
  //height: 100%;
}

.index_img_box {
  position: absolute;
  top: 0;
  width: 750px;
  padding: 40px 0 0 40px;
  box-sizing: border-box;
}

.index_logo {
  width: 88px;
  height: 122px;
}

.index_banner {
  width: 100%
}

.index_banner > .img {
  width: 100%;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
}

.copyright
  width 100%
  color $gray
  margin-top 20px
  text-align center

</style>
